<template>
  <v-container fluid>
    <!--    <ProgressCircular :isLoaded="isLoaded" />-->
    <v-row v-if="!editCard">
      <v-col cols="12" sm="6">
        <template v-if="isServicePointExist">
          <h3 class="mb-1">Розташування</h3>
          <div>
            <span>Орендодавець:</span>
            <router-link
              :to="{
                name: 'PartnerCard',
                params: { id: currentCompanyId, partnerId: getServicePointLinkedToDevice.balance_holder.id },
              }"
              class="font-weight-medium text-decoration-none ml-2 d-inline-block"
            >
              {{ getServicePointLinkedToDevice.balance_holder.name }}
            </router-link>
          </div>
          <div>
            <span>Назва точки:</span>
            <router-link
              :to="{
                name: 'ServicePointCard',
                params: { id: currentCompanyId, servicePointId: getServicePointLinkedToDevice.id },
              }"
              class="font-weight-medium text-decoration-none ml-2 d-inline-block"
            >
              {{ getServicePointLinkedToDevice.name }}
            </router-link>
          </div>
          <div>
            <span>Адреса точки:</span>
            <router-link
              :to="{
                name: 'ServicePointCard',
                params: { id: currentCompanyId, servicePointId: getServicePointLinkedToDevice.id },
              }"
              class="font-weight-medium text-decoration-none ml-2 d-inline-block"
            >
              {{ getServicePointLinkedToDevice.address.country }}, {{ getServicePointLinkedToDevice.address.city }},
              {{ getServicePointLinkedToDevice.address.street }},
              {{ getServicePointLinkedToDevice.address.building_number }}
            </router-link>
          </div>
          <div v-if="isTerminalExist && getTerminalLinkedToDevice.location">
            <span>Місцерозташування:</span>
            <span class="font-weight-medium ml-2">{{ getTerminalLinkedToDevice.location }}</span>
          </div>
          <div v-if="isTerminalExist">
            <span>Термінал:</span>
            <router-link
              :to="{ name: 'TerminalCard', params: { id: currentCompanyId, terminalId: getTerminalLinkedToDevice.id } }"
              class="font-weight-medium text-decoration-none ml-2 d-inline-block"
            >
              T{{ getTerminalLinkedToDevice.id }}
            </router-link>
          </div>
          <div>
            <span>Номер на терміналі:</span>
            <span class="font-weight-medium ml-2">{{ device.connection_number || 'Check for API' }}</span>
          </div>
        </template>

        <template>
          <h3 class="mt-6 mb-1">Основні дані</h3>
          <div>
            <span>ID:</span>
            <span class="font-weight-medium ml-2">{{ device.id }}</span>
          </div>
          <div>
            <span>Тип:</span>
            <span class="font-weight-medium ml-2">{{ getMachineDesc(device.type) }} ({{ getDeviceTitle(device.type) }})</span>
          </div>
          <div>
            <span>Виробник:</span>
            <span class="font-weight-medium ml-2">{{ device.brand }}</span>
          </div>
          <div>
            <span>Модель:</span>
            <span class="font-weight-medium ml-2">{{ device.model }}</span>
          </div>
          <div>
            <span>Серійний номер:</span>
            <span class="font-weight-medium ml-2">{{ device.serial_number }}</span>
          </div>
          <div>
            <span>Інвентарний номер:</span>
            <span class="font-weight-medium ml-2">{{ device.inventory_number }}</span>
          </div>

          <div class="mt-6">
            <span>Дата виробництва:</span>
            <span class="font-weight-medium ml-2">{{ device.manufacture_date | getShortDate }}</span>
          </div>
          <div>
            <span>Дата інсталяції:</span>
            <span class="font-weight-medium ml-2">{{ device.purchase_date | getShortDate }}</span>
          </div>
        </template>
      </v-col>

      <v-col cols="12" sm="6">
        <div class="select-wrapper d-flex align-center">
          <PagingSelect
            :options="deviceEvents"
            :options-meta="deviceEventsMeta"
            :options-params="deviceEventsOptions"
            item-value="event_code"
            class="mr-3"
            outlined
            dense
            hide-details
            clearable
            @change="(value) => (selectedDeviceEvent = value)"
          >
            <template v-slot:selection="{ item }">
              <span>{{ item.name.uk }} ({{ item.event_code }})</span>
            </template>
            <template v-slot:label>
              <span>Оберіть команду</span>
            </template>
            <template v-slot:item="{ item }">
              <span>{{ item.name.uk }} ({{ item.event_code }})</span>
            </template>
          </PagingSelect>
          <v-btn
            @click="sendDeviceCommand()"
            color="blue-grey lighten-3"
            :disabled="!selectedDeviceEvent"
            :dark="!!selectedDeviceEvent"
            depressed
            small
          >
            <v-icon>mdi-share</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <template v-if="isServicePointExist">
          <h3 class="mb-1">Розташування</h3>
          <div>
            <span>Орендодавець:</span>
            <span class="font-weight-medium ml-2">{{ getServicePointLinkedToDevice.balance_holder.name }} </span>
          </div>
          <div>
            <span>Назва точки:</span>
            <span class="font-weight-medium ml-2">{{ getServicePointLinkedToDevice.name }}</span>
          </div>
          <div>
            <span>Адреса точки:</span>
            <span class="font-weight-medium ml-2">
              {{ getServicePointLinkedToDevice.address.country }}, {{ getServicePointLinkedToDevice.address.city }},
              {{ getServicePointLinkedToDevice.address.street }},
              {{ getServicePointLinkedToDevice.address.building_number }}
            </span>
          </div>
          <div v-if="isTerminalExist && getTerminalLinkedToDevice.location">
            <span>Місцерозташування:</span>
            <span class="font-weight-medium ml-2">{{ getTerminalLinkedToDevice.location }}</span>
          </div>
          <div v-if="isTerminalExist">
            <span>Термінал:</span>
            <span class="font-weight-medium ml-2">T{{ getTerminalLinkedToDevice.id }}</span>
          </div>
          <div>
            <span>Номер на терміналі:</span>
            <span class="font-weight-medium ml-2">{{ device.connection_number || 'Check for API' }}</span>
          </div>
        </template>

        <template>
          <h3 class="mt-6 mb-1">Основні дані</h3>
          <div>
            <span>ID:</span>
            <span class="font-weight-medium ml-2">{{ device.id }}</span>
          </div>
          <div>
            <span>Тип:</span>
            <span class="font-weight-medium ml-2">{{ getMachineDesc(device.type) }} ({{ getDeviceTitle(device.type) }})</span>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span>Виробник:</span>
            <v-text-field v-model="editedItem.brand" class="ml-2" dense hide-details></v-text-field>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span>Модель:</span>
            <v-text-field v-model="editedItem.model" class="ml-2" dense hide-details></v-text-field>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span>Серійний номер:</span>
            <v-text-field v-model="editedItem.serial_number" class="ml-2" dense hide-details></v-text-field>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span>Інвентарний номер:</span>
            <v-text-field v-model="editedItem.inventory_number" class="ml-2" dense hide-details></v-text-field>
          </div>
          <div class="mt-6 d-flex align-baseline custom-field">
            <span class="date-title">Дата виробництва:</span>
            <v-menu
              v-model="menuManufactureDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedItem.manufacture_date"
                  prepend-icon="mdi-calendar"
                  hint="рiк-мiсяць-число"
                  persistent-hint
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                ></v-text-field>
              </template>
              <v-date-picker v-model="editedItem.manufacture_date" @input="menuManufactureDate = false"></v-date-picker>
            </v-menu>
          </div>
          <div class="d-flex align-baseline custom-field">
            <span class="date-title">Дата інсталяції:</span>
            <v-menu
              v-model="menuPurchaseDate"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="editedItem.purchase_date"
                  prepend-icon="mdi-calendar"
                  hint="рiк-мiсяць-число"
                  persistent-hint
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                ></v-text-field>
              </template>
              <v-date-picker v-model="editedItem.purchase_date" @input="menuPurchaseDate = false"></v-date-picker>
            </v-menu>
          </div>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import convertMachinesTypes from '@/mixins/convertDevicesTypes'
import convertDate from '@/mixins/convertDate'
import cloneDeep from '@/mixins/cloneDeep'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { ROUTER_LINKS } from '@/const/router-links.enum'
import { isObjectEmpty } from '@/helpers/isObjectEmpty'
import PagingSelect from '@/components/common/PagingSelect'
import { deviceEventTypeId } from '@/const/constructor/eventTypes'

export default {
  name: 'GeneralInfo',

  components: {
    PagingSelect,
    // ProgressCircular: () => import('@/components/common/ProgressCircular'),
  },

  mixins: [convertMachinesTypes, convertDate, cloneDeep],

  props: {
    device: {
      type: Object,
      required: true,
      default: () => {},
    },
    isDataSaved: {
      type: Boolean,
      required: true,
      default: false,
    },
    currentTab: {
      required: true,
      default: null,
    },
    isLoaded: {
      type: Boolean,
      required: true,
      default: false,
    },
    editCard: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ROUTER_LINKS: ROUTER_LINKS,
    menuManufactureDate: false,
    menuPurchaseDate: false,
    editedItem: {},
    deviceEventsOptions: null,
    selectedDeviceEvent: null,
    showLoading: false,
  }),

  computed: {
    ...mapState('dictionaries', ['machinesTypesDictionary']),
    ...mapState('infoConstructor', ['additionalInfoEvents', 'additionalInfoEventsMeta', 'deviceEvents', 'deviceEventsMeta']),
    ...mapGetters('devices', ['getTerminalLinkedToDevice', 'getServicePointLinkedToDevice']),

    currentCompanyId() {
      return this.$route.params.id
    },
    isServicePointExist() {
      return !isObjectEmpty(this.getServicePointLinkedToDevice)
    },
    isTerminalExist() {
      return !isObjectEmpty(this.getTerminalLinkedToDevice)
    },
    defaultItem() {
      return this.cloneObjectDeep(this.device)
    },
  },

  watch: {
    editCard(val) {
      if (!val) {
        this.initialize()
      }
    },
    isDataSaved(val) {
      if (val) {
        this.$emit('saveData', this.editedItem)
      }
    },
  },

  created() {
    this.initialize()
  },

  beforeDestroy() {
    this.SET_SELECTED_DEVICE({})
  },

  methods: {
    ...mapActions('infoConstructor', ['loadAdditionalInfoEventCodes']),
    ...mapActions('monitoring', ['sendMonitoringTerminalCommand', 'sendMonitoringDeviceCommand']),
    ...mapMutations('devices', ['SET_SELECTED_DEVICE']),

    async initialize() {
      this.deviceEventsOptions = {
        loadingFunction: this.loadAdditionalInfoEventCodes,
        payload: {
          eventType: deviceEventTypeId,
          forSearch: 1,
        },
      }
      this.editedItem = { ...this.defaultItem }
    },

    async sendDeviceCommand() {
      const payload = {
        companyId: this.currentCompanyId,
        terminalId: this.device.terminal.id,
        deviceId: this.device.id,
        directiveTypeId: this.selectedDeviceEvent.event_code,
      }
      await this.sendMonitoringDeviceCommand(payload)
    },
  },
}
</script>

<style scoped lang="scss">
.select-wrapper {
  max-width: 500px;
}

.custom-field {
  max-width: 500px !important;
}

.date-title {
  min-width: 150px;
}
</style>
